
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Loader from "@/components/General/Loader.vue";
import { Category } from "@/models/category.interface";
import Empty from "@/components/General/Empty.vue";
import { Notification } from "@/models/notification.interface";
import { Product as IProduct } from "@/models/product.interface";
import CategoryBreadcrumbs from "@/components/Layout/Common/CategoryBreadcrums.vue";
import { Category as CategoryInterface } from "@/models/category.interface";
import { Product } from "@/models/product.interface";
import ProductCard from "@/components/Layout/Client/ProductCard.vue";
import { StyleCheck } from "@/mixins/style-check";

@Component({
  components: {
    Loader,
    Empty,
    CategoryBreadcrumbs,
    ProductCard,
  },
})
export default class CatalogueByCategory extends mixins(
  Navigation,
  StyleCheck
) {
  loader = false;
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };
  categories: { all: Category[]; tree: Category[] } = {
    all: [],
    tree: [],
  };

  private changePage() {
    this.navigate(
      "/catalogue/category/" +
        this.$route.params.category_id +
        "/" +
        this.pagination.page
    );
  }

  private goToCategory(category: CategoryInterface) {
    this.navigate("/catalogue/category/" + category.id + "/" + 1);
  }

  private goToProductDetail(product: Product) {
    this.navigate("/catalogue/product/investment/" + product.id + "/detail");
  }

  private async getProductsByCategory(
    page: string,
    size: number
  ): Promise<void> {
    await this.$store
      .dispatch("products/getProductsByCategory", {
        page,
        size,
        status: this.$constants.STATUS.ACTIVE,
        category_id: this.$route.params.category_id,
      })
      .then(() => {
        window.scrollTo(0, 0);
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Products.fetchError.products"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private async created() {
    window.scrollTo(0, 0);
    this.loader = true;
    await this.loadCategories();
    await this.getProductsByCategory(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
  }

  private get products(): IProduct[] {
    let response = this.$store.getters["products/getProducts"];
    this.pagination.totalItems = response.totalItems;
    this.pagination.page = parseInt(response.currentPage);

    if (response?.products) {
      return response.products;
    } else {
      return [];
    }
  }

  private get totalPages() {
    const total =
      this.pagination.totalItems /
      Number(process.env.VUE_APP_DEFAULT_PAGINATION);
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  @Watch("$route.params", { deep: true })
  private async handler() {
    this.loader = true;
    await this.getProductsByCategory(
      this.$route.params.page,
      this.pagination.itemsPerPage
    );
    this.loader = false;
  }

  private async loadCategories() {
    this.categories = await this.$store
      .dispatch("categories/getAssignableCategories")
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Categories.fetchError.get"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get currentCategory() {
    let category = {};
    for (let index = 0; index < this.categories.all.length; index++) {
      if (
        this.categories.all[index].id ==
        parseInt(this.$route.params.category_id)
      ) {
        category = this.categories.all[index];
      }
    }
    return category;
  }

  private get navigationForCategories() {
    const categories = [];
    for (let index = 0; index < this.categories.all.length; index++) {
      if (
        categories.length < 10 &&
        this.categories.all[index].fk_category ==
          parseInt(this.$route.params.category_id)
      ) {
        categories.push(this.categories.all[index] as never);
      }
    }
    return categories;
  }
}
